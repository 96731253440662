.cardSection{
    padding: 2rem 1rem;
    border: 1px solid gray;
    border-radius: 1rem;
    box-shadow: azure;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    box-shadow: 4px 4px 5px #aaaaaa;
    width: 50%;
}
.resentUserVerificatinLinkText{
    color: #c16767;
    float: right;
    cursor: pointer;
}
.flexEnd{
    display: flex;
    justify-content: flex-end;
}
.ml30{
    margin-left : 30px !important;
}
.roundButton{
    border-radius: 4rem !important;
}